import styled, { css } from "styled-components/macro";
import Device from "device";

export const Form = styled.form`
  position: relative;
  z-index: 5;
`;
export const Input = styled.input`
  border: none;
  background: none;
  width: 100%;
  border-bottom: 1px solid var(--dark-bg, #fff);
  color: var(--dark-bg, #fff);
  padding: 0 0 8px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  &:focus-visible,
  &:focus {
    outline: none;
  }
  &:-internal-autofill-selected,
  &:-webkit-autofill {
    background-color: unset !important;
    color: var(--dark-bg, #fff) !important;
  }
`;
export const Label = styled.label`
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--dark-bg, #fff);
`;
export const Fieldset = styled.fieldset`
  border: none;
  margin: 0 32px 40px 0;
  padding: 0;
  width: 100%;

  @media ${Device.tablet} {
    margin: 0 32px 32px 0;
  }

  ${(props) =>
    props.error &&
    css`
      ${Input} {
        border-bottom: 1px solid var(--error, red);
      }
      ${Label} {
        background: var(--error, red);
        color: #fff;
      }
    `};
`;
export const FieldsetWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  @media ${Device.laptop} {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  ${Fieldset} {
    @media ${Device.tablet} {
      max-width: 304px;
    }
    @media ${Device.laptop} {
      max-width: 264px;
    }
  }
`;
export const Button = styled.button`
  background: var(--dark-bg, #fff);
  border: none;
  padding: 12px 24px;
  border-radius: 50px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--accent-primary);
  text-align: center;
  cursor: pointer;

  ${(props) =>
    props.loading &&
    css`
      background: var(--placeholder);
      pointer-events: none;
    `};
`;
export const Buttons = styled.div`
  display: flex;
`;
