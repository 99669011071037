import styled, { css } from "styled-components";
import Device from "device";

const Style = css`
  display: inline-block;
  padding: 12px 24px;
  background: var(--accent-secondary);
  color: var(--dark-bg);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border-radius: 24px;
  border: none;
  text-align: center;
  cursor: pointer;
  width: auto;
  text-decoration: none;
  user-select: none;
  width: 100%;

  @media ${Device.tablet} {
    width: auto;
  }
`;
const ButtonEl = styled.button`
  ${Style}
`;
const LinkEl = styled.a`
  ${Style}
`;

export const Button = ({ children, href = "", ...props }) => {
  return (
    <>
      {href.length ? (
        <LinkEl href={href} $ {...props}>
          {children}
        </LinkEl>
      ) : (
        <ButtonEl $ {...props}>
          {children}
        </ButtonEl>
      )}
    </>
  );
};
