import Device from "device";
import { ReactComponent as SvgCopy } from "shared/assets/icons/icon-copy.svg";
import { ReactComponent as SvgGroup } from "shared/assets/icons/icon-group.svg";
import { ReactComponent as SvgKey } from "shared/assets/icons/icon-key.svg";
import { ReactComponent as SvgLayers } from "shared/assets/icons/icon-layers-round.svg";
import { ReactComponent as SvgTriangles } from "shared/assets/triangles.svg";
import styled, { css } from "styled-components";

export const Section = styled.section`
  width: 100%;
  position: relative;
  padding: 140px 0 0 0;
  user-select: none;
  max-width: 100%;
  overflow-x: clip;

  @media ${Device.laptop} {
    padding: 152px 0 0 0;
  }
`;
export const Triangles = styled(SvgTriangles)`
  width: 640px;
  height: 960px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -320px;
  pointer-events: none;
  z-index: -1;

  @media ${Device.tablet} {
    right: 0;
    left: auto;
  }
`;
export const Wrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Left = styled.div`
  flex: 1;
`;
export const Title = styled.h2`
  margin: 0 0 63px 0;
  text-align: left;
`;
export const Pic = styled.picture`
  display: none;

  @media ${Device.laptop} {
    display: block;
    width: 592px;
    height: 592px;
    max-width: 592px;
  }

  img {
    max-width: 100%;
  }
`;
const IconCss = css`
  width: 24px;
  height: 24px;
`;
export const IconLayers = styled(SvgLayers)`
  ${IconCss}
`;
export const IconKey = styled(SvgKey)`
  ${IconCss}
`;
export const IconCopy = styled(SvgCopy)`
  ${IconCss}
`;
export const IconGroup = styled(SvgGroup)`
  ${IconCss}
`;

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const Item = styled.div`
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0 0 40px 0;
  padding: 0 40px 0 0;
`;
export const ItemText = styled.div`
  font-size: 18px;
  margin: 18px 0 0 0;
`;
