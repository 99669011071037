import React, { useContext, useState } from "react";
import HomeContext from "shared/contexts/HomeContext";
import * as S from "./styled";
import * as Form from "shared/components/Form";

import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmail } from "class-validator";

const validationSchema = Yup.object().shape({
  contact_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("invalid email")
    .required("Required")
    .test("is-valid", "invalid email", (value) => value && isEmail(value)),
  name: Yup.string().required("Required"),
  comment: Yup.string().optional(),
});

const API_URL = "/api/v1/customers/";

const GetStarted = () => {
  const [status, setStatus] = useState(false);
  const [error, setError] = useState(null);
  const initialFormValues = {
    contact_name: "",
    email: "",
    name: "",
  };
  const { gsRef, nameInputRef } = useContext(HomeContext);

  const handleOnSubmit = async (values, actions) => {
    let response;
    try {
      if (!values.comment || !values.comment.trim()) {
        delete values.comment;
      }

      response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      console.log("response", response);

      if (response.status === 201) {
        // actions.resetForm();
        setStatus(true);
      } else {
        // actions.resetForm();
        setError("Something went wrong. Please try again later.");
        setTimeout(() => {
          setError(null);
        }, 5000);
        throw new Error("Request failed.");
      }
    } catch (e) {
      setError("Something went wrong. Please try again later.");
      console.log(e);
    }
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: handleOnSubmit,
  });

  return (
    <S.Section id="getstarted" ref={gsRef}>
      <S.StyledContainer>
        <S.Wrap>
          <S.Title>Get started</S.Title>
          <Form.Form onSubmit={formik.handleSubmit}>
            <Form.FieldsetWrap>
              <Form.Fieldset
                error={
                  formik.touched.contact_name &&
                  Boolean(formik.errors.contact_name)
                }
              >
                <Form.Label htmpFor="contact_name">Name *</Form.Label>
                <Form.Input
                  name="contact_name"
                  ref={nameInputRef}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.contact_name}
                  autoComplete="off"
                />
              </Form.Fieldset>
              <Form.Fieldset
                error={formik.touched.email && Boolean(formik.errors.email)}
              >
                <Form.Label htmpFor="email">Email *</Form.Label>
                <Form.Input
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.email}
                  autoComplete="off"
                />
              </Form.Fieldset>
              <Form.Fieldset
                error={formik.touched.name && Boolean(formik.errors.name)}
              >
                <Form.Label htmpFor="name">Your company *</Form.Label>
                <Form.Input
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.name}
                  autoComplete="off"
                />
              </Form.Fieldset>
            </Form.FieldsetWrap>
            <Form.Fieldset
              error={formik.touched.comment && Boolean(formik.errors.comment)}
            >
              <Form.Label htmpFor="comment">Comment</Form.Label>
              <Form.Input
                name="comment"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.values.comment}
              />
            </Form.Fieldset>
            {status ? (
              <S.Success>
                <S.IconSuccess />
                Request has been sent
              </S.Success>
            ) : (
              <Form.Buttons>
                <Form.Button
                  type="submit"
                  loading={formik.isSubmitting ? 1 : 0}
                  disabled={formik.isSubmitting}
                >
                  Send request
                </Form.Button>
                {error ? <S.Error>{error}</S.Error> : null}
              </Form.Buttons>
            )}
          </Form.Form>
          <S.Rocket>
            <img
              srcSet="/i/Rocket.png, /i/Rocket@2x.png 2x"
              src="/i/Rocket.png"
              alt=""
            />
          </S.Rocket>
        </S.Wrap>
      </S.StyledContainer>
    </S.Section>
  );
};

export default GetStarted;
