import styled from "styled-components/macro";
import { Link } from "react-router-dom";

import { ReactComponent as SvgTop } from "shared/assets/icons/icon-top.svg";

export const Wrap = styled.section`
  width: 100%;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
`;
export const Copy = styled.div`
  color: var(--object-primary);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.1em;
  padding: 10px 0;
`;
export const CopyLink = styled.a`
  display: inline-block;
  text-decoration: none;
`;
export const Nav = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const NavItem = styled(Link)`
  color: var(--object-primary);
  margin: 0 32px 0 0;
  padding: 10px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.1em;
  display: inline-block;
  text-decoration: none;

  &:last-child {
    margin: 0;
  }
`;
export const IconTop = styled(SvgTop)`
  width: 24px;
  height: 24px;
`;
export const ToTop = styled.div`
  display: inline-block;
  cursor: pointer;
`;
