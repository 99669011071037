import * as S from "./styled";
import { Container } from "shared/components/Container";

const Circle = () => {
  return (
    <S.CircleWrap>
      <S.CircleS />
      <S.CircleM />
      <S.CircleL />
    </S.CircleWrap>
  );
};

const Integration = () => {
  return (
    <S.Section id="integration">
      <Container>
        <S.Title>DeFi & CeFi integration</S.Title>
      </Container>
      <S.Wrap>
        <S.WalletXS />
        <S.WalletS />
        <S.WalletM>
          <S.IconLock />
        </S.WalletM>
        <S.WalletL>
          <S.IconApp />
        </S.WalletL>
        <S.MainWallet>
          <S.IconW />
        </S.MainWallet>
        <S.WalletL>
          <S.IconMetamusk />
        </S.WalletL>
        <S.WalletM>
          <S.IconTrust />
        </S.WalletM>
        <S.WalletS />
        <S.WalletXS />
        <Circle />
      </S.Wrap>
      <S.Text>
        Use WalletConnect <br />
        to connect Safe or Secure Wallet to any <span>DeFi-protocol</span>
      </S.Text>
      <S.SupportText>
        Support for swaps, staking, lending <br />
        and&nbsp;borrowing
      </S.SupportText>
      <S.Additional>
        <S.AdditionalItem>
          <S.IconChain />
          <S.AdditionalText>
            Connect your trading and investment accounts with&nbsp;supported
            brokers
          </S.AdditionalText>
        </S.AdditionalItem>
        <S.AdditionalItem>
          <S.IconLayer />
          <S.AdditionalText>
            Easily control funds across all your strategies
            from&nbsp;one&nbsp;point
          </S.AdditionalText>
        </S.AdditionalItem>
      </S.Additional>
    </S.Section>
  );
};

export default Integration;
