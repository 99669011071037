import React, { useState, useEffect, useContext, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AppContext from "shared/contexts/AppContext";
import HomeContext from "shared/contexts/HomeContext";
import { Header } from "shared/components/Header";
import Solution from "./components/Solution";
import Keys from "./components/Keys";
import Exchanges from "./components/Exchanges";
import Technology from "./components/Technology";
import Integration from "./components/Integration";
import GetStarted from "./components/GetStarted";

const HomePage = ({ navigate, path }) => {
  const { setMenuOpened } = useContext(AppContext);
  const gsRef = useRef(null); // get started section
  const nameInputRef = useRef(null); // name input section

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScrollTo = (target) => {
    let el = document.getElementById(target);
    el && el.scrollIntoView({ behavior: "smooth", block: "start" });

    if (target === "getstarted" && nameInputRef.current) {
      nameInputRef.current.focus();
    }

    setTimeout(() => {
      setMenuOpened(false);
    }, 400);
  };

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const homeState = {
    gsRef,
    nameInputRef,
    scrollPosition,
    handleScrollTo,
  };

  return (
    <HomeContext.Provider value={homeState}>
      <HelmetProvider>
        <Helmet>
          <title>
            Multik | Secure solution for managing multiple exchange and
            blockchain accounts
          </title>
          <meta
            name="description"
            content="Secure solution for managing multiple exchange and blockchain accounts"
          />
        </Helmet>

        <Header />
        <Solution />
        <Keys />
        <Exchanges />
        <Technology />
        <Integration />
        <GetStarted />
      </HelmetProvider>
    </HomeContext.Provider>
  );
};

export default HomePage;
