import styled, { css } from "styled-components/macro";
import { ReactComponent as SvgLogo } from "shared/assets/logo-multik.svg";
import { ReactComponent as SvgMenu } from "shared/assets/icons/icon-menu.svg";
import { ReactComponent as SvgClose } from "shared/assets/icons/icon-close.svg";
import { Button } from "shared/components/Button";
import Device from "device";

export const Logo = styled(SvgLogo)`
  width: 96px;
  height: 24px;
`;
export const StyledButton = styled(Button)`
  width: auto;
  z-index: 99;
`;
export const NavWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const Nav = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  flex-direction: column;
  display: none;
  padding: 20px;
  justify-content: center;
  align-items: flex-end;

  @media ${Device.laptop} {
    position: static;
    min-height: unset;
    display: flex;
    padding: 0;
    flex-direction: row;
  }
`;
export const NavItem = styled.div`
  color: var(--object-primary);
  margin: 0;
  padding: 12px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: inline-block;
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  @media ${Device.laptop} {
    margin: 0 32px 0 0;
  }
`;
export const IconMenu = styled(SvgMenu)`
  width: 24px;
  height: 24px;
`;
export const IconClose = styled(SvgClose)`
  width: 24px;
  height: 24px;
`;
export const MenuToggle = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0 0 0 16px;
  width: 24px;
  height: 24px;
  z-index: 999;
  position: relative;

  @media ${Device.laptop} {
    display: none;
  }
`;

export const Section = styled.section`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 99;
  backdrop-filter: blur(24px);

  @media ${Device.laptop} {
  }

  ${(props) =>
    props.opened &&
    css`
      bottom: 0;

      ${Nav} {
        display: flex;
        transform: scale(1);
        opacity: 1;
      }
    `};
`;
