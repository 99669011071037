import { Link } from "react-router-dom";
import * as S from "./styled";

const NotFound = () => {
  return (
    <S.Section>
      <S.Wrap>
        <S.Title>Nothing to see here!</S.Title>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </S.Wrap>
    </S.Section>
  );
};

export default NotFound;
