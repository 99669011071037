import { useContext } from "react";
import { Button } from "shared/components/Button";
import { Container } from "shared/components/Container";
import HomeContext from "shared/contexts/HomeContext";
import * as S from "./styled";

const Technology = () => {
  const { handleScrollTo } = useContext(HomeContext);
  return (
    <S.Section id="technology">
      <Container>
        <S.Title>Technology highlights</S.Title>
        <S.Wrap>
          <div>
            <S.List>
              <S.ListItem>
                <S.IconLock />
                <S.ListItemText>
                  Real-time connection to supported exchanges
                </S.ListItemText>
              </S.ListItem>
              <S.ListItem>
                <S.IconLock />
                <S.ListItemText>
                  Private keys can't be stolen, because they don't exist thanks to MPC technology
                </S.ListItemText>
              </S.ListItem>
              <S.ListItem>
                <S.IconLock />
                <S.ListItemText>
                  Multi-user confirmation on any transaction
                </S.ListItemText>
              </S.ListItem>
              <S.ListItem>
                <S.IconLock />
                <S.ListItemText>
                  Support for existing blockchain and Safe wallets
                </S.ListItemText>
              </S.ListItem>
            </S.List>
          </div>
          <S.Pic>
            <img
              srcSet="/i/lock.png, /i/lock@2x.png 2x"
              src="/i/lock.png"
              alt=""
            />
          </S.Pic>
        </S.Wrap>
        <S.ButtonWrap>
          <Button onClick={() => handleScrollTo("getstarted")}>
            Get started
          </Button>
        </S.ButtonWrap>
      </Container>
    </S.Section>
  );
};

export default Technology;
