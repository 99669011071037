import styled from "styled-components";
import Device from "device";
import { ReactComponent as SvgSuccess } from "shared/assets/icons/icon-check.svg";
import { Container } from "shared/components/Container";

export const Section = styled.section`
  width: 100%;
  position: relative;
  padding: 128px 0 0 0;
  max-width: 100%;

  @media ${Device.laptop} {
    padding: 152px 0 0 0;
  }
`;
export const Wrap = styled.div`
  background: var(--accent-primary);
  padding: 32px;
  border-radius: 16px;
  width: 100%;
  position: relative;
`;
export const Title = styled.h2`
  text-align: left;

  @media ${Device.laptop} {
    text-align: center;
  }
`;
export const Rocket = styled.picture`
  display: none;

  img {
    width: 100%;
    max-width: 100%;
  }

  @media ${Device.tablet} {
    display: block;
    width: 344px;
    height: 344px;
    position: absolute;
    top: -64px;
    right: 0;
    pointer-events: none;
    z-index: 2;
    user-select: none;
  }
  @media ${Device.laptop} {
    width: 280px;
    height: 280px;
  }
`;
export const Success = styled.div`
  display: flex;
  line-height: 24px;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 400;
`;
export const IconSuccess = styled(SvgSuccess)`
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
`;
export const Error = styled.div`
  background: var(--error);
  color: var(--dark-bg);
  padding: 10px 20px;
  border-radius: 4px;
  margin: 0 0 0 32px;
`;
export const StyledContainer = styled(Container)`
  @media ${Device.mobileOnly} {
    padding: 0;
  }
`;
