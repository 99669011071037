import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import GlobalStyles from "./globalStyles";
import "./override.css";

import AppContext from "./shared/contexts/AppContext";

import HomePage from "./screens/HomePage/";
import NotFound from "./screens/NotFound/";
import Layout from "./screens/Layout";

function App() {
  const [menuOpened, setMenuOpened] = useState(false);

  const appState = {
    menuOpened,
    setMenuOpened,
  };

  return (
    <AppContext.Provider value={appState}>
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
