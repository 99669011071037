import styled, { css } from "styled-components";
import Device from "device";

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: relative;
  flex-wrap: wrap;

  ${(props) =>
    props.align &&
    css`
      align-items: ${props.align};
    `};

  ${(props) =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `};

  ${(props) =>
    props.wrap &&
    css`
      flex-wrap: ${props.wrap};
    `};

  @media ${Device.tablet} {
    padding-left: 32px;
    padding-right: 32px;
  }
`;
