import styled, { css, keyframes } from "styled-components";
import Device from "device";
import { ReactComponent as SvgMetanusk } from "shared/assets/integration/metamusk-logo.svg";
import { ReactComponent as SvgTrust } from "shared/assets/integration/trustwallet-logo.svg";
import { ReactComponent as SvgApp } from "shared/assets/integration/app.svg";
import { ReactComponent as SvgLock } from "shared/assets/integration/lock.svg";
import { ReactComponent as SvgW } from "shared/assets/integration/w-logo.svg";
import { ReactComponent as SvgChain } from "shared/assets/icons/icon-chain.svg";
import { ReactComponent as SvgLayer } from "shared/assets/icons/icon-layers.svg";

export const Section = styled.section`
  width: 100%;
  position: relative;
  padding: 128px 0 0 0;
  user-select: none;
  position: relative;
  max-width: 100%;
  overflow-x: clip;

  @media ${Device.laptop} {
    padding: 160px 0 0 0;
  }
`;
export const Title = styled.h2`
  padding: 0 20px;

  @media ${Device.laptop} {
    padding: 0;
  }
`;
export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const Wallet = styled.div`
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  border-radius: 300px;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  transition: all 0.6s;
  transform: scale(1);

  &:hover {
    transform: scale(1.1);
  }

  @media ${Device.laptop} {
    width: 96px;
    min-width: 96px;
    max-width: 96px;
    height: 96px;
    margin: 20px;
  }
`;
export const MainWallet = styled(Wallet)`
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  background-color: var(--accent-primary);
  background-image: url("/i/Wallet@2x.png");
  background-position: 50%;
  background-size: 60px;
  background-repeat: no-repeat;
  position: relative;

  @media ${Device.laptop} {
    width: 160px;
    min-width: 160px;
    max-width: 160px;
    height: 160px;
    background-size: 120px;
  }
`;
export const WalletXS = styled(Wallet)`
  display: none;

  @media ${Device.laptop} {
    display: block;
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    opacity: 0.1;
  }
`;
export const WalletS = styled(Wallet)`
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  opacity: 0.2;

  @media ${Device.laptop} {
    width: 72px;
    min-width: 72px;
    max-width: 72px;
    height: 72px;
  }
`;
export const WalletM = styled(Wallet)`
  opacity: 0.8;
`;
export const WalletL = styled(Wallet)`
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;

  @media ${Device.laptop} {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    height: 120px;
  }
`;
export const IconW = styled(SvgW)`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  width: 32px;
  height: 32px;

  @media ${Device.laptop} {
    width: 48px;
    height: 48px;
  }
`;
const IconCss = css`
  width: 100%;
  height: 100%;
`;
export const IconMetamusk = styled(SvgMetanusk)`
  ${IconCss}
`;
export const IconTrust = styled(SvgTrust)`
  ${IconCss}
`;
export const IconLock = styled(SvgLock)`
  ${IconCss}
`;
export const IconApp = styled(SvgApp)`
  ${IconCss}
`;
export const Text = styled.div`
  font-weight: 700;
  margin: 32px 0 16px;
  text-align: center;
  padding: 0 16px;

  span {
    white-space: nowrap;
  }

  @media ${Device.laptop} {
    padding: 0 32px;
    margin: 48px 0 24px;
  }
`;
export const SupportText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  opacity: 0.5;
  padding: 0 16px;

  @media ${Device.laptop} {
    padding: 0 32px;
  }
`;
export const Additional = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  flex-direction: column;

  @media ${Device.tablet} {
    flex-direction: row;
    margin: 40px 0 0 0;
  }
`;
export const AdditionalItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 384px;
  margin: 32px 0 0 0;

  @media ${Device.laptop} {
    margin: 0 16px;
  }
`;
export const AdditionalText = styled.div`
  text-align: center;
  margin: 16px 0 0 0;
`;
const AdditionalIconCss = css`
  width: 24px;
  height: 24px;
`;
export const IconChain = styled(SvgChain)`
  ${AdditionalIconCss}
`;
export const IconLayer = styled(SvgLayer)`
  ${AdditionalIconCss}
`;

const loopScales = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

export const CircleWrap = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 2px;
  transform: translate(-50%, -50%);
`;
const CircleCss = css`
  background: var(--accent-primary);
  opacity: 0.1;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  animation-name: ${loopScales};
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transition-timing-function: cubic-bezier(0.1, 0.9, 0.9, 0.1);
  transform-origin: center;
`;
export const CircleL = styled.div`
  ${CircleCss}
  width: 560px;
  height: 560px;
  margin-top: -280px;
  margin-left: -280px;
  animation-delay: 0s;

  @media ${Device.laptop} {
    width: 1120px;
    height: 1120px;
    margin-top: -560px;
    margin-left: -560px;
  }
`;
export const CircleM = styled.div`
  ${CircleCss}
  width: 400px;
  height: 400px;
  margin-top: -200px;
  margin-left: -200px;
  animation-delay: 1s;

  @media ${Device.laptop} {
    width: 800px;
    height: 800px;
    margin-top: -400px;
    margin-left: -400px;
  }
`;
export const CircleS = styled.div`
  ${CircleCss}
  width: 240px;
  height: 240px;
  margin-top: -120px;
  margin-left: -120px;
  animation-delay: 2s;

  @media ${Device.laptop} {
    width: 480px;
    height: 480px;
    margin-top: -240px;
    margin-left: -240px;
  }
`;
