import Device from "device";
import styled from "styled-components";

import { ReactComponent as SvgArrow } from "shared/assets/icons/icon-arrow.svg";

export const Wrap = styled.section`
  width: 100%;
  position: relative;
  padding: 128px 0 0 0;
  user-select: none;
  position: relative;
  max-width: 100%;

  @media ${Device.laptop} {
    padding: 160px 0 0 0;
  }
`;
export const Triangle = styled.div`
  display: none;

  @media ${Device.laptop} {
    display: block;
    position: absolute;
    background: linear-gradient(
      to bottom,
      #3282ff 0%,
      rgba(50, 130, 255, 0) 66.66%
    );
    opacity: 0.5;
    transform: rotate(-45deg);
    width: 640px;
    height: 640px;
    bottom: -320px;
    left: -320px;
    z-index: -1;
    pointer-events: none;
  }
`;
export const Title = styled.h2``;
export const Subtitle = styled.div`
font-size: 50%;
font-weight: normal;
margin-top: .5rem;
margin-bottom: 1.5rem;
`;

export const Transfer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  grid-auto-rows: auto;
  width: 100%;
  margin: 0 0 40px 0;
  position: relative;

  @media ${Device.tablet} {
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }
`;
export const Key = styled.picture`
  display: none;

  @media ${Device.tablet} {
    display: block;
    width: 280px;
    height: 280px;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: -1;
    pointer-events: none;
    transform: translateY(-50%);
  }

  img {
    width: 280px;
    height: 280px;
  }
`;
export const TransferItem = styled.div`
  background: rgba(200, 255, 255, 0.1);
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 100%;
  padding: 16px;
  margin: 4px 0;
  color: #c8ffff;
  cursor: default;

  @media ${Device.tablet} {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 25px;
    margin: 8px 0;
  }
`;
export const TransferColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const TransferColumnBorder = styled(TransferColumn)`
  border: 2px solid rgba(200, 255, 255, 0.1);
  border-radius: 16px;
  padding: 16px;
`;
export const TransferColumnLast = styled(TransferColumn)`
  flex-direction: row;

  @media ${Device.tablet} {
    flex-direction: column;
  }

  ${TransferItem} {
    margin: 0 4px;

    @media ${Device.tablet} {
      margin: 8px 0;
    }
  }
`;
export const TransferTitle = styled.h3`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 0 0 16px 0;
  color: #c8ffff;
`;
const Arrow = styled(SvgArrow)`
  width: 16px;
  height: 16px;

  @media ${Device.tablet} {
    width: 32px;
    height: 32px;
  }
`;
export const ArrowIn = styled(Arrow)`
  position: absolute;
  left: 50%;
  top: -20px;
  transform: translateX(-50%) rotate(90deg);

  @media ${Device.tablet} {
    left: -34px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
export const ArrowOut = styled(Arrow)`
  position: absolute;
  bottom: -18px;
  transform: rotate(90deg);

  @media ${Device.tablet} {
    bottom: auto;
    right: -34px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
export const ArrowOutTop = styled(ArrowOut)`
  left: 50px;

  @media ${Device.tablet} {
    top: 20px;
    left: unset;
    transform: none;
  }
`;
export const ArrowOutBottom = styled(ArrowOut)`
  right: 50px;

  @media ${Device.tablet} {
    bottom: 20px;
    top: unset;
    right: -34px;
    transform: none;
  }
`;
export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  grid-auto-rows: minmax(100px, auto);

  @media ${Device.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
export const ListItem = styled.div`
  width: 100%;
`;
export const ListItemText = styled.div`
  font-size: 18px;
  line-height: 24px;
`;
export const ListPic = styled.picture`
  width: 64px;
  height: 64px;
  margin: 0 0 16px 0;
  display: inline-block;

  img {
    width: 64px;
    height: 64px;
  }
`;
export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0 0 0;
`;
