import Device from "device";
import { createGlobalStyle } from "styled-components";
import "./theme.css";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing:border-box;
    vertical-align: top;
  }
  html,  body {
    height: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }
  body {
    display: flex;
    background: var(--background);
    color: var(--object-primary);
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
  }
  h2 {
    margin: 0 0 40px 0;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    user-select: none;

    @media ${Device.laptop} {
      font-size: 40px;
      line-height: 48px;
      margin: 0 0 80px 0;
    }
  }
  #root {
    display: flex;
    width: 100%;
    min-height: 100%;
    flex: 1;
    flex-direction: column;
  }
  .svg-logo-1 {
    fill: var(--object-primary);
  }
  .svg-logo-2 {
    fill: var(--accent-secondary);
  }
  a {
    color: var(--object-primary);
  }
`;

export default GlobalStyles;
